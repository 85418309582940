import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./ClickableRedCard.css";

function ClickableRedCard({ text, text2, width, hasBorder, onClick }) {
    return (
        <Card
            className={`clickable-red-card ${
                hasBorder && "clickable-red-card-border"
            }`}
            style={{ width: width }}
            onClick={onClick}
        >
            <Card.Body className="py-1">
                <Row>
                    <Col>
                        <Card.Text className="fs-5 fw-bold">{text}</Card.Text>
                    </Col>
                    { text2 != null 
                        ? <Col>
                            <Card.Text className="fs-5 fw-bold cred-card-right-column">{text2}</Card.Text>
                        </Col>
                        : <></>
                    }
                </Row>
            </Card.Body>
        </Card>
    );
}

export default ClickableRedCard;
