import './Title.css'
import USCLogoHeader from "../../components/USCLogoHeader";
import { useNavigate, useParams } from 'react-router-dom';
import { exerciseEventsUrl } from '../../util/API';

function Title(){
    const navigate = useNavigate();
    const { first, last, session, building, room } = useParams();

    //set up event listener 
    const exerciseEventSource = new EventSource(exerciseEventsUrl);

    exerciseEventSource.onerror = () => {
        console.log("event source error");
        console.log(exerciseEventSource);
    }
    
    exerciseEventSource.addEventListener('start-survey', event => {
        var data = JSON.parse(event.data);
        // go to survey title page if the event is for this session
        if (data.firstName == first && data.lastName == last && data.sessionId == session) {
            console.log("add event listener working")
            navigate(`/room/surveytitle/${first}/${last}/${session}/${building}/${room}`);
        }
    })

    // navigate to case scenario page when admin says so
    exerciseEventSource.addEventListener('start-case-scenario', event => {
        var data = JSON.parse(event.data);
        // if the this room and the room from the event are the same, navigate
        if (data.firstName == first && data.lastName == last && data.room == room) {
            navigate(`/room/casescenario/${first}/${last}/${session}/${room}`);
        }
    })

    return(
        <>
            <USCLogoHeader/>
            <p id="room-title"> My Friend Morgan</p>
        </>
    );
}

export default Title;