import { useState } from "react";
import "./AdminSurveysSubmitted.css";
import USCLogoHeader from "../../components/USCLogoHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AdminBackground from "./AdminBackground";
import YellowButton from "../../components/YellowButton";
import ClassIDLabel from "../../components/ClassIDLabel";
import ActivityDashboard from "../../components/ActivityDashboard";
import { startCaseScenarioAPI, submitEventsUrl } from "../../util/API";
import { useNavigate, useParams } from "react-router-dom";

function AdminSurveysSubmitted() {
  let {first, last, session, room} = useParams();
  let [roomCounts, setRoomCounts] = useState([]);
  const navigate = useNavigate();

  // To open a connection to the server to begin receiving events from it, create a new EventSource object with the URL of a script that generates the events.
  const surveyEventSource = new EventSource(submitEventsUrl);

  surveyEventSource.onerror = () => {
    console.log("error");
  }

  surveyEventSource.addEventListener('survey-response', event => {
    var json = JSON.parse(event.data);
    
    if (first == json.firstName && session == json.session) {
      setRoomCounts(json.arrayResponses);
    }
  })

  let generateCaseScenario = async () => {
    let response = await startCaseScenarioAPI(first, last, session);

    if (response == "ECONNABORTED") {
      alert("Please refresh the page and click the button again");
    } else {
      navigate(`/admin/dashboard/indiv-response/${first}/${last}/${session}`);
    }
  }

  
  return (
    <div className="AdminSurveysSubmitted">
      <AdminBackground />
      <ClassIDLabel class_id={session} isWhite />
      <Container fluid className="ps-0">
        <Row>
          <Col sm={12} md={7} lg={5}>
            <ActivityDashboard title={"Surveys Submitted"} roomCounts={roomCounts}/>
          </Col>
          <Col>
            <Row className="mb-5 pb-5" id="adminsurveyssubmitted-header">
                <USCLogoHeader />
            </Row>
            <Row id="adminsurveyssubmitted-button" className="pt-5">
                <YellowButton text="Generate Case Scenario" onClick={generateCaseScenario} />
            </Row>
          </Col>
        </Row>
      </Container>
      <ClassIDLabel class_id={session} isWhite />
    </div>
  );
}

export default AdminSurveysSubmitted;
