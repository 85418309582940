import '../../App.css';
import React, { useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import SurveyRow from '../../components/SurveyRow';
import YellowButton from '../../components/YellowButton';
import Wait from '../general/Wait';
import { exerciseEventsUrl, submitSurveyResponse } from '../../util/API';
import './StudentPages.css';

function Survey({inElc}) {
  const navigate = useNavigate();
  let {first, last, session, building, room} = useParams()
  let [surveyData, setSurveyData] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleCheckboxChange = (orderNum, option, isChecked) => {
    setSurveyData((prevState) => ({
      ...prevState,
      [orderNum]: {
        ...prevState[orderNum],
        [option]: isChecked,
      },
    }));
    console.log(surveyData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      surveyData = Object.assign({'first': first,'last': last, 'sessionID': session, 'building': building, 'room':room, surveyData});

      const response = await submitSurveyResponse(surveyData);
      if (response.status == 500) {
        alert(response.data);
        return;
      }
      setHasSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (hasSubmitted) {
    if (inElc) {
      const exerciseEventSource = new EventSource(exerciseEventsUrl);

      // navigate to case scenario page when admin says so
      exerciseEventSource.addEventListener('start-case-scenario', event => {
          var data = JSON.parse(event.data);
          // if the this room and the room from the event are the same, navigate
          if (data.room == room) {
              navigate(`/student/case/${first}/${last}/${session}/${building}/${room}`);
          }
      })
      return (
        <Wait message="Please wait for activity to start" />
      )
    } else {
      return (
        <Wait message="Thank you for submitting"/>
      );
    }
  }

  const orderNum = 1;

  return (
    <Container className='student-survey-borderStyle'>
        <h2 className='student-survey-header'>Survey</h2>
        <strong><p>Please use a checkmark to indicate a yes or true answer. Please leave a line blank to signify a no or false answer.</p></strong>
        <span className='student-survey-setWidth'>During your lifetime:</span>
        <span className='student-survey-setWidth'>In the past year:</span>
        <br/><br/>
        <SurveyRow orderNum={orderNum} question="Have you stolen something from work, a friend, family member, etc?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 1} question="Shoplifted?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 2} question="Lied or not told the truth?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 3}question="Cheated on your spouse or significant other?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 4}question="Kept merchandise money when the sales clerk or company made a mistake in your favor?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 5}question="Lied on an employment application?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 6}question="Used illegal drugs?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 7} question="Cheated on a test or exam?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 8} question="Broken the law or committed a crime not already mentioned in this survey excluding speeding?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 9} question="Plagiarized another person’s work?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 10} question="Compromised your personal ethics to do something required for your job?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 11} question="Switched the price tag on merchandise? " onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 12} question="Knowingly bought merchandise (perhaps for a special event or occasion), used it, then returned it?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 13} question="Unhooked or tampered with the car’s odometer?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 14} question="Falsified work-related expense, travel, or time records?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 15} question="Charged personal items to the company (copies, etc) and didn’t pay for them?" onCheckboxChange={handleCheckboxChange}/>
        <SurveyRow orderNum={orderNum + 16} question="Falsified a subordinates performance evaluation?" onCheckboxChange={handleCheckboxChange}/>
        <div className='student-survey-button'>
        <YellowButton onClick={handleSubmit} text="Submit"/>
        </div>
    </Container>
  );
}

export default Survey;








