import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Container, Form, Modal, ModalBody, Stack } from "react-bootstrap";
import './AdminSessionsPage.css';
import USCLogoHeader from "../../components/USCLogoHeader";
import ClickableRedCard from "../../components/ClickableRedCard";
import YellowButton from "../../components/YellowButton";
import AdminBackground from "./AdminBackground";
import { createSessionAPI, deleteSessionAPI, getSessionsAPI } from "../../util/API";

function AdminSessionsPage() {
    let [newSessionName, setNewSessionName] = useState();
    let [creatingNewSession, setCreatingNewSession] = useState(false);
    let [showModal, setShowModal] = useState(false);
    let [sessionList, setSessionList] = useState([]);
    let { first, last } = useParams();
    let navigate = useNavigate();

    let updateSessionList = () => {
        getSessionsAPI(first, last, (response) => {
            setSessionList(response);
        })
    }

    useEffect(() => {
        updateSessionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let deleteSession = (sessionId) => {
        if (window.confirm(`Are you sure you want to delete Session ${sessionId}?`)) {
            deleteSessionAPI(first, last, sessionId, () => {
                updateSessionList();
            });
        }
    }

    let openSession = (sessionId) => {
        const foundSession = sessionList.find(session => session.sessionId == sessionId);
        if (foundSession.rooms != null && foundSession.rooms.length > 0) {
            alert("This session already has rooms attached to it");
        } else {
            const session = sessionList.find(session => session.sessionId === sessionId);
            if (session.surveyAtElc || (!session.surveyAtElc && session.surveyDone)) {
                navigate(`/admin/roomselect/${first}/${last}/${sessionId}`);
            } else {
                navigate(`/admin/survey/${first}/${last}/${sessionId}`);
            }
        }

    }

    let onCancel = () => {
        setNewSessionName();
        setCreatingNewSession(false);
    }

    let onFormSubmit = (e) => {
        e.preventDefault();
        const isInteger = /^\d+$/.test(newSessionName);
        if (!newSessionName || !isInteger) {
            alert("Invalid class section");
        } else {
            setShowModal(true);
        }
    }

    let createSession = (surveyInClass) => {
        createSessionAPI(first, last, newSessionName, surveyInClass, () => {
            updateSessionList();
        })
        setCreatingNewSession(false);
        setNewSessionName();
        setShowModal(false);
    }

    return (
        <Container fluid>
            <AdminBackground />
            <USCLogoHeader />
            <Container className="px-5 py-3" fluid>
                {sessionList.map((session, index) => {
                    return (
                        <Row className="mb-3" key={index}>
                            <Col lg={{span: 2}} md={{span: 2}} xs={{span: 12}} className="sessions-class-label fs-5 fw-bold">Class {index + 1}:</Col>
                            <Col className="pb-2" lg={{span: 4}} md={{span: 6}} xs={{span: 8}}>
                                <ClickableRedCard text={session.sessionId} onClick={() => openSession(session.sessionId)}/>
                            </Col>
                            <Col xs={{span: 4}}>
                                { !creatingNewSession && <YellowButton text={'Delete'} onClick={() => deleteSession(session.sessionId)}/> }
                            </Col>
                        </Row>
                    );
                })}
                <Row className="mb-3">
                    <Col lg={{span: 4, offset: 2}} md={{span: 6, offset: 2}} xs={{span: 8}}>
                        <Form onSubmit={onFormSubmit}>
                            { creatingNewSession 
                                ? <Form.Control
                                    autoFocus type="text"
                                    name="session_name"
                                    className="new-session-form-text py-2"
                                    placeholder="Enter Class Section"
                                    onChange={(e) => setNewSessionName(e.target.value)}
                                /> 
                                : <ClickableRedCard text={"Create New Session +"} onClick={() => setCreatingNewSession(true)}/> 
                            }
                        </Form>
                    </Col>
                    <Col xs={{span: 4}}>
                        {creatingNewSession && <YellowButton text={'Cancel'} onClick={onCancel} /> }
                    </Col>
                </Row>
            </Container>

            <Modal centered show={showModal} className="admin-sessions-modal">
                <ModalBody className="mx-auto py-5">
                    <div className="mt-4 mb-4">Are you taking the survey at the ELC?</div>
                    
                    <Stack direction="horizontal" gap={4} className="mb-4">
                    <div className="ms-auto">
                        <YellowButton text={'Yes'} width="60px" onClick={() => createSession(true)} />
                    </div>
                    <div className="me-auto">
                        <YellowButton text={'No'} width="60px" onClick={() => createSession(false)} />
                    </div>
                </Stack>
                </ModalBody>
            </Modal>
        </Container>
    )
}

export default AdminSessionsPage;