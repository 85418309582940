import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import AdminBackground from "./AdminBackground";
import USCLogoHeader from "../../components/USCLogoHeader";
import YellowButton from "../../components/YellowButton";
import './AdminSignInPage.css';
import { useNavigate } from "react-router-dom";

function AdminSignInPage() {
    let [firstName, setFirstName] = useState();
    let [lastName, setLastName] = useState();
    let navigate = useNavigate();

    let signIn = (e) => {
        e.preventDefault();
        if (!firstName && !lastName) {
            alert('First and last name are empty')
        } else if (!firstName) {
            alert('First name is empty');
        } else if (!lastName) {
            alert('Last name is empty');
        } else {
            navigate(`/admin/sessions/${firstName}/${lastName}`);
        }
    }

    return (
        <Container fluid>
            <AdminBackground />
            <USCLogoHeader />
            <Form className="mx-5 px-5 py-5" onSubmit={signIn}>
                <Row className="pb-5">
                    <Col sm={12} md={6} lg={4}>
                        <Form.Group controlId="fname">
                            <Form.Label className="profsignin-name-label fs-5">Professor First Name</Form.Label>
                            <Form.Control className="profsignin-textbox" type="text" onChange={(e) => {setFirstName(e.target.value)}}/>
                        </Form.Group>
                        <Form.Group controlId="lname">
                            <Form.Label className="profsignin-name-label fs-5">Professor Last Name</Form.Label>
                            <Form.Control className="profsignin-textbox" type="text" onChange={(e) => setLastName(e.target.value)}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="pt-5 mt-5 py-5">
                    <Col sm={12} md={{span: 3, offset: 9}} className="d-grid pt-5">
                        <YellowButton text={"Sign In"} type={"submit"} />
                    </Col>
                </Row>
            </Form>
        </Container>

        
    )
}

export default AdminSignInPage;