import axios from 'axios';
import { getServerUrl } from './serverInfo';

const serverUrl = getServerUrl();

export const exerciseEventsUrl = `${serverUrl}/exercise/events`
export const submitEventsUrl = `${serverUrl}/submit/events`

export async function getSessionsAPI(firstName, lastName, callback) {
    const endpoint = '/session/get-sessions'
    const url = `${serverUrl}${endpoint}?firstName=${firstName}&lastName=${lastName}`;
    try {
        const response = await axios.get(url);
        callback(response.data);
    } catch (error) {
        console.log(error);
    }
}

export async function getOutsideURLAPI(firstName, lastName, sessionId, callback) {
    const endpoint = '/exercise/outside-elc-url'
    const url = `${serverUrl}${endpoint}?firstName=${firstName}&lastName=${lastName}&sessionId=${sessionId}`;
    try {
        const response = await axios.get(url);
        callback(response.data);
    } catch (error) {
        console.log(error);
    }
}

export async function createSessionAPI(firstName, lastName, sessionId, surveyAtElc, callback) {
    const endpoint = '/session/create';
    const data = {
        firstName: firstName,
        lastName: lastName,
        sessionId: sessionId,
        surveyAtElc: surveyAtElc,
        surveyDone: false
    }

    const url = serverUrl + endpoint;
    try {
        await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        callback();
    } catch (error) {
        console.log(error);
    }
}

export async function deleteSessionAPI(firstName, lastName, sessionId, callback) {
    const endpoint = '/session/delete';
    const data = {
        firstName: firstName,
        lastName: lastName,
        sessionId: sessionId,
    }

    const url = serverUrl + endpoint;
    try {
        await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        callback();
    } catch (error) {
        console.log(error);
    }
}

export async function getAvailableRoomsAPI(callback) {
    const endpoint = "/session/available-rooms";
    const url = `${serverUrl}${endpoint}`;

    try {
        const response = await axios.get(url);
        callback(response.data);
    } catch (error) {
        console.log(error);
    }
}

export async function startSessionAPI(
    firstName,
    lastName,
    sessionName,
    building,
    rooms,
    successCallback,
    errorCallback
) {
    const endpoint = "/session/start";
    const url = `${serverUrl}${endpoint}`;
    const data = {
        firstName: firstName,
        lastName: lastName,
        sessionId: sessionName,
        building: building,
        rooms: rooms,
    };

    let zoomRooms = [];
    if (building == "Zoom") {
        for (let i = 1; i < parseInt(rooms) + 1; i++) {
            zoomRooms.push(`${firstName}${lastName}${sessionName} Breakout Room ${i}`);
        }
        data.rooms = zoomRooms;
    }

    await axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).catch((e) => {
        errorCallback(e);
        return;
    });

    successCallback();
}


export async function startGroupResponseAPI(firstName, lastName, sessionName){
    
    const endpoint = "/exercise/start-group-response"
    const url = `${serverUrl}${endpoint}`

    const data = {
        firstName: firstName,
        lastName: lastName,
        sessionName: sessionName,
    }


    try {
        console.log("data:" + data.firstName);
        
        await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 2000
        })

    } catch (error) {
        console.log(error);
        return error.code;
    }

}

export async function getIndividualResults(firstName, lastName, session, callback) {
    const endpoint = '/exercise/get-individual-results'
    const url = `${serverUrl}${endpoint}?firstName=${firstName}&lastName=${lastName}&session=${session}`
    try {
        const response = await axios.get(url, {
            timeout: 2000
        });
        callback(response.data);
        
    } catch (error) {
        console.log(error);
        callback(error.code);
    }
}

export async function getGroupResults(firstName, lastName, session, callback) {
    const endpoint = '/exercise/get-group-results'
    const url = `${serverUrl}${endpoint}?firstName=${firstName}&lastName=${lastName}&session=${session}`
    try {
        const response = await axios.get(url);
        callback(response.data);
        
    } catch (error) {
        console.log(error);
    }
}

export async function getGroupResultsBreakdown(firstName, lastName, session, room, callback) {
    const endpoint = '/exercise/get-group-breakdown-results'
    const url = `${serverUrl}${endpoint}?firstName=${firstName}&lastName=${lastName}&session=${session}&room=${room}`
    try {
        const response = await axios.get(url);
        callback(response.data);
        
    } catch (error) {
        console.log(error);
    }
}




export async function startSurveyAPI(firstName, lastName, sessionName){
    
    const endpoint = "/exercise/start-survey"
    const url = `${serverUrl}${endpoint}`

    const data = {
        firstName: firstName,
        lastName: lastName,
        sessionName: sessionName,
    }


    try {
        console.log("data:" + data.firstName);
        
        await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        console.log("after post")

    } catch (error) {
        console.log(error);
    }

}
export async function startCaseScenarioAPI(firstName, lastName, sessionId) {
    const endpoint = '/exercise/start-case-scenario';
    const data = {
        firstName: firstName,
        lastName: lastName,
        sessionId: sessionId,
    }

    const url = serverUrl + endpoint;
    try {
        await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 2000
        })
    } catch (error) {
        console.log(error);
        return error.code;
    }
}

export async function getCaseScenarioInfo(firstName, lastName, sessionId, room) {
    const endpoint = '/exercise/case-scenario-info';
    const url = `${serverUrl}${endpoint}?firstName=${firstName}&lastName=${lastName}&sessionId=${sessionId}&room=${room}`;
    let info = await axios.get(url, {
        timeout: 5000
    }).catch(error => {
        if (error.code == "ECONNABORTED") {
            return error.code;
        }
        return error.response;
    });
    return info;
}


export async function getCaseScenarioBehaviors(firstName, lastName, sessionId, room) {
    const endpoint = '/exercise/case-scenario-behaviors';
    const url = `${serverUrl}${endpoint}?firstName=${firstName}&lastName=${lastName}&sessionId=${sessionId}&room=${room}`;
    let info = await axios.get(url).catch(error => {
        return error.response;
    });
    console.log(info);
    return info;
}

export async function submitGroupResponse(data) {
    const endpoint = '/submit/group-response';

    const url = serverUrl + endpoint;
    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function submitIndividualResponse(data) {
    const endpoint = '/submit/individual-response';

    const url = serverUrl + endpoint;
    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function submitSurveyResponse(data) {
    const endpoint = '/submit/survey-response';

    const url = serverUrl + endpoint;
    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export async function downloadResults(firstName, lastName, sessionId) {
    const endpoint = '/session/download-results';
    const url = `${serverUrl}${endpoint}?firstName=${firstName}&lastName=${lastName}&sessionId=${sessionId}`;
    let config = {
        responseType: 'blob'
    }

    let response;

    try {
        response = await axios.get(url, config);
    } catch (error) {
        return error.response;
    }

    const theUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = theUrl;
    link.setAttribute('download', `${firstName}_${lastName}_${sessionId}_Results.zip`);
    document.body.appendChild(link);
    link.click();
    return response;
}