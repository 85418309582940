const serverURL_prod = "https://myfriendmorganbackend.elcexercises.org";
const serverURL_dev = "http://localhost:8000";

export function getServerUrl() {
    return serverURL_prod;
    if (process.env.NODE_ENV == "production") {
        return serverURL_prod;
    } else {
        return serverURL_dev;
    }
}
