import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import AdminBackground from "./AdminBackground";
import USCLogoHeader from "../../components/USCLogoHeader";
import YellowButton from "../../components/YellowButton";
import AdminDummyComponent from "../../components/AdminDummyComponent";
import './AdminSignInPage.css';

function AdminDummyPage() {
    return (
        <Container fluid>
            <AdminBackground />
            <USCLogoHeader />
            {/* <YellowButton text={"hi "}/> */}
            This is Admin Dummy Page.
            <AdminDummyComponent/>
        </Container>

        
    )
}

export default AdminDummyPage;