import './SurveyTitle.css'
import USCLogoHeader from "../../components/USCLogoHeader";
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Image } from 'react-bootstrap';
import { exerciseEventsUrl } from '../../util/API';

function SurveyTitle() {
    let {first, last, session, building, room} = useParams();
    const navigate = useNavigate();
    
    const exerciseEventSource = new EventSource(exerciseEventsUrl);

    // navigate to case scenario page when admin says so
    exerciseEventSource.addEventListener('start-case-scenario', event => {
        var data = JSON.parse(event.data);
        // if the this room and the room from the event are the same, navigate
        if (data.room == room) {
            navigate(`/room/casescenario/${first}/${last}/${session}/${room}`);
        }
    })

    
    let url = encodeURI(`${window.location.protocol}//${window.location.host}/student/signin-all/${first}/${last}/${session}`);
    return(
        <>
            <USCLogoHeader/>
            <div id="container">
                <p >Survey:</p>
                <div id='surveytitle-qrcode-container'>
                    <Image width="200px" src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${url}`} alt='QR Code failed to load' />
                </div>
                <p id="link">{url}</p>
            </div>
        </>
    );
}

export default SurveyTitle;