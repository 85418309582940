import React, { useEffect } from "react";
import background from '../../images/usc-background.jpg';

function AdminBackground() {
    useEffect(() => {
        document.body.style.backgroundImage = `url(${background})`;
        document.body.style.backgroundPosition = "66% 0%";

        return () => {
            document.body.style.backgroundImage = null;
            document.body.style.backgroundPosition = null;
        }
    });

    return (
        <div></div>
    );
}

export default AdminBackground;