import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

function SurveyRow({ orderNum, question, onCheckboxChange }) {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const handleCheckbox1Change = (event) => {
    const isChecked = event.target.checked;
    setIsChecked1(isChecked);
    onCheckboxChange(orderNum, 'lifetime', isChecked);
  };

  const handleCheckbox2Change = (event) => {
    const isChecked = event.target.checked;
    setIsChecked2(isChecked);
    onCheckboxChange(orderNum, 'recently', isChecked);
  };

  const addPadding = {
    margin: '10px 35px',
  };

  return (
    <Form>
      <Row>
        <Col xs={3} md={2} lg={1}>
          <Form.Group>
            <Form.Check
              type="checkbox"
              checked={isChecked1}
              onChange={handleCheckbox1Change}
              style={addPadding}
            />
          </Form.Group>
        </Col>
        <Col xs={3} md={2} lg={1}>
          <Form.Group>
            <Form.Check
              type="checkbox"
         
              checked={isChecked2}
              onChange={handleCheckbox2Change}
              style={addPadding}
            />
          </Form.Group>
        </Col>
        <Col xs={6} md={8} lg={10}>
          <div className="question-container">
            <Form.Label>{question}</Form.Label>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default SurveyRow;


