// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#scenario-title{\n    text-align: center;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 30px;\n    margin-top:50px;\n}\n\n#description-box{\n    margin:auto;\n    width:70%;\n    \n}\n\n#description-box p {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 25px;\n    \n}\n\n#scenario-link{\n    font-weight: bold !important;\n    font-size: 40px;\n    color:#990000;\n    text-align: center;\n    margin-top:50px;\n}\n\n#roomcasescenario-qrcode-container {\n    text-align: center;\n    margin-top: 50px;\n}", "",{"version":3,"sources":["webpack://./src/pages/room/RoomCaseScenario.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,SAAS;;AAEb;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;;AAEnB;;AAEA;IACI,4BAA4B;IAC5B,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');\n\n#scenario-title{\n    text-align: center;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 30px;\n    margin-top:50px;\n}\n\n#description-box{\n    margin:auto;\n    width:70%;\n    \n}\n\n#description-box p {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 25px;\n    \n}\n\n#scenario-link{\n    font-weight: bold !important;\n    font-size: 40px;\n    color:#990000;\n    text-align: center;\n    margin-top:50px;\n}\n\n#roomcasescenario-qrcode-container {\n    text-align: center;\n    margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
