import React from "react";
import { Button } from "react-bootstrap";
import "./YellowButton.css"

function YellowButton({text, type, width, onClick}) {
    return (
        <Button 
            className="yellow-btn" 
            type={`${type ?? 'button'}`} // button, submit, reset
            style={{width: width}}
            onClick={onClick}
        >
            {text}
        </Button>
    );
}

export default YellowButton;