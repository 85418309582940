import React from "react";
import USCLogoHeader from "../../components/USCLogoHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import AdminBackground from "../admin/AdminBackground";
import YellowButton from "../../components/YellowButton";
import { useNavigate } from "react-router-dom";
import "./Home.css";

function Home() {

  const navigate = useNavigate();

  let navToAdmin = () => {
    navigate('/admin/signin');
  };

  let navToRoom = () => {
    navigate('/room/signin');
  };


  return (
    <div>
    
      <AdminBackground />
      <USCLogoHeader />
      <div className="home-container">
        <h1> My Friend Morgan</h1>
        <div className="home-adminbutton">
        <YellowButton text="Admin Sign In" onClick={navToAdmin}/>
        </div>
        <div>
        <YellowButton text="Room Sign In" onClick={navToRoom}/>
        </div>
        
      </div>
    
    </div>
  );
}

export default Home;
