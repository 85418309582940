import "./GroupResultsBreakdown.css";
import React, { useState, useEffect } from "react";
import USCLogoHeader from "../../components/USCLogoHeader";
import YellowButton from "../../components/YellowButton";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap"
import { deleteSessionAPI, downloadResults, getGroupResultsBreakdown } from "../../util/API";

function GroupResultsBreakdown() {
  const { first, last, session, room } = useParams();
  const navigate = useNavigate();

  let [caseLifetimeBehaviors, setCaseLifetimeBehaviors] = useState([]);
  let [caseRecentlyBehaviors, setCaseRecentlyBehaviors] = useState([])
  let [caseLifetimeBehaviorsDiv, setCaseLifetimeBehaviorsDiv] = useState([]);
  let [caseRecentlyBehaviorsDiv, setCaseRecentlyBehaviorsDiv] = useState([])
 
  let [recommendTotal, setRecommendTotal] = useState([]);
  let [heightYes, setHeightYes] = useState([]);
  let [heightNo, setHeightNo] = useState([]);
  let [responsesYes, setResponsesYes] = useState([]);
  let [responsesNo, setResponsesNo] = useState([]);
  let [yesResponsesDiv, setYesResponsesDiv] = useState([]);
  let [noResponsesDiv, setNoResponsesDiv] = useState([]);
  
  let [genderTotal, setGenderTotal] = useState([]);
  let [heightFemale, setHeightFemale] = useState([]);
  let [heightMale, setHeightMale] = useState([]);
  let [heightNonbinary, setHeightNonbinary] = useState([]);

  let [choiceTotal, setChoiceTotal] = useState([]);
  let [heightLoyalty, setHeightLoyalty] = useState([]);
  let [heightInterest, setHeightInterest] = useState([]);
  
  let [groupResponse, setGroupResponse] = useState([]);
  let [groupResponseDiv, setGroupResponseDiv] = useState([]);

  useEffect(() => {
    getGroupResultsBreakdown(first, last, session, room, (response) => {
      setRecommendTotal(response[0].length + response[1].length);
      setHeightYes(response[0].length);
      setHeightNo(response[1].length);
      setResponsesYes(response[0]);
      setResponsesNo(response[1]);

      setGenderTotal(response[2].length + response[3].length + response[4].length);
      setHeightFemale(response[2].length);
      setHeightMale(response[3].length);
      setHeightNonbinary(response[4].length);

      setChoiceTotal(response[5].length + response[6].length);
      setHeightLoyalty(response[5].length);
      setHeightInterest(response[6].length);

      setGroupResponse(response[7]);

      setCaseLifetimeBehaviors(response[8][0].behaviors.lifetime);
      setCaseRecentlyBehaviors(response[8][0].behaviors.pastyear);
    });
  }, []);

  let endSession = async () => {
    let endSession = window.confirm("Are you sure you want to end the session?");
    if (endSession) {
      let response = await downloadResults(first, last, session);
      if (response.status == 500) {
        alert("Something went wrong, the session might not exist");
      } else {
        await deleteSessionAPI(first, last, session);
        navigate("/admin/signin");
      }
    }
  }

useEffect(() => {
  if (responsesYes) {
    let yesResponsesDiv = responsesYes.map((obj, index) => {
      return (<p id="irtext-box">
      {obj.recommendation} {"-"} {obj.reason}{" "}
      </p>)
    });
    setYesResponsesDiv(yesResponsesDiv);
  }
  if (responsesNo) {
    let noResponsesDiv = responsesNo.map((obj, index) => {
      return (<p id="irtext-box">
      {obj.recommendation} {"-"} {obj.reason}{" "}
      </p>)
    });
    setNoResponsesDiv(noResponsesDiv);
  }
  if (groupResponse) {
    let groupResponseDiv = groupResponse.map((obj, index) => {
      return (<p id="grouptext1">
      {obj.recommendation} {"-"} {obj.reason}{" "}
      </p>)
    });
    setGroupResponseDiv(groupResponseDiv);
  }
  if(caseLifetimeBehaviors){
    let caseLifetimeBehaviorsDiv = caseLifetimeBehaviors.map((obj, index) => {
      return (<p>{"- "}{obj}</p>)
    });
    setCaseLifetimeBehaviorsDiv(caseLifetimeBehaviorsDiv);
  }
  if(caseRecentlyBehaviors){
    let caseRecentlyBehaviorsDiv = caseRecentlyBehaviors.map((obj, index) => {
      return (<p>{"- "}{obj}</p>)
    });
    setCaseRecentlyBehaviorsDiv(caseRecentlyBehaviorsDiv);
  }
}, [groupResponse]);
  
  return (
    <Container>
      <Row>
      <p><USCLogoHeader /></p>
      <p id="results-title">Do you recommend Morgan?</p>
      <p id="results-subtitle">{room} Breakdown</p>
      </Row>
      
    <Row>
      <Col>
      <p id="morgansbehavior-title"> Morgan’s Behaviors in their Lifetime</p>
      <div id="description-box">
        {caseLifetimeBehaviorsDiv}
      </div>
      </Col>
      <Col>
      <p id="morgansbehavior-title"> Morgan’s Behaviors in the Past Year:</p>
      <div id="description-box">
        {caseRecentlyBehaviorsDiv}
      </div>
      </Col>

    </Row>
      
      <p id="individualresponses-title">Individual Responses</p>
      <div id="irdescription-box">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: 50,
            paddingLeft: 70,
          }}
        >
          <div className="rectanglesDiv">
            <p className="text">Recommended Morgan?</p>
            <div className="rectangle3" style={{ height: heightYes/recommendTotal*200}}/>
            <div className="rectangle4" style={{ height: heightNo/recommendTotal*200}}/>
          </div>
          <div className="rectanglesDiv">
            <p className="text">Morgan is ___________________</p>
            <div className="rectangle1" style={{ height: heightFemale/genderTotal*200}}/>
            <div className="rectangle2" style={{ height: heightMale/genderTotal*200}}/>
            <div className="rectangle5" style={{ height: heightNonbinary/genderTotal*200}}/>
          </div>
          <div className="rectanglesDiv">
            <p className="text">___ impacted my choice</p>
            <div className="rectangle3" style={{ height: heightLoyalty/choiceTotal*200}}/>
            <div className="rectangle4" style={{ height: heightInterest/choiceTotal*200}}/>
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: 50,
            paddingLeft: 70,
            paddingBottom: 20,
          }}
        >
          <div>
            <p id="answer-titleYes">Yes</p>
            <p id="answer-titleNo">No</p>
          </div>
          <div>
            <p id="answer-title">Female</p>
            <p id="answer-title">Male</p>
            <p id="answer-title">Nonbinary</p>
          </div>
          <div>
            <p id="answer-title2">Loyalty</p>
            <p id="answer-title2">Company</p>
          </div>
        </div>
          <div>{yesResponsesDiv}</div>
          <div>{noResponsesDiv}</div>
      </div>

      <p id="individualresponses-title">Group Response</p>
      <div id="grdescription-box">
        <div>{groupResponseDiv}</div>
      </div>

      <p>
        <div id="groupbreakdown-back-btn">
          <YellowButton text={"Back"} onClick={() => navigate(-1)}/>
        </div>
        <div id="groupbreakdown-endsession-btn">
          <YellowButton text={"End Session"} onClick={endSession} />
        </div>
      </p>
    </Container>
  );
}

export default GroupResultsBreakdown;
