import "./AdminGroupsResponded.css";
import USCLogoHeader from "../../components/USCLogoHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import {React, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import AdminBackground from "./AdminBackground";
import YellowButton from "../../components/YellowButton";
import ActivityDashboard from "../../components/ActivityDashboard";
import ClassIDLabel from "../../components/ClassIDLabel";
import { useNavigate, useParams } from "react-router-dom";
import { submitEventsUrl } from "../../util/API";

function AdminFlow() {
  const { first, last, session } = useParams();
  const navigate = useNavigate();

  /* Added event source */
  let [roomCounts, setRoomCounts] = useState([]);
  const groupResponseEventSource = new EventSource(submitEventsUrl);

  groupResponseEventSource.onerror = () => {
    console.log("error");
  }

  groupResponseEventSource.addEventListener('group-response', event => {
    var json = JSON.parse(event.data);
    
    if (first == json.firstName && session == json.session) {
      setRoomCounts(json.arrayResponses);
    }
  })

  
  /* *********************** */

  let goToIndividualResultsPage = () => navigate(`/admin/results/individual/${first}/${last}/${session}`);

  return (
    <div className="AdminFlow">
      <AdminBackground />
      <ClassIDLabel class_id={session} isWhite />
      <Container fluid className="ps-0">
        <Row>
          <Col sm={12} md={7} lg={5}>
            <ActivityDashboard title={"Groups Responded"} roomCounts={roomCounts}/>
          </Col>
          <Col>
            <Row className="mb-5 pb-5" id="admingroupsresponded-header">
                <USCLogoHeader />
            </Row>
            <Row id="admingroupsresponded-button" className="pt-5">
                <YellowButton text="Generate Results" onClick={goToIndividualResultsPage} />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AdminFlow;
