// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#container{\n    width:80%;\n    margin:auto;\n    margin-top: 30px;\n}\n\n#container p{\n    font-family: 'Inter', sans-serif;\n    font-weight: 700;\n    font-size: 40px;\n    text-align:center;\n}\n\n#link{\n    color:#990000;\n}\n\n#surveytitle-qrcode-container {\n    text-align: center;\n    margin-top: 50px;\n    margin-bottom: 50px;\n}", "",{"version":3,"sources":["webpack://./src/pages/room/SurveyTitle.css"],"names":[],"mappings":"AAEA;IACI,SAAS;IACT,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');\n\n#container{\n    width:80%;\n    margin:auto;\n    margin-top: 30px;\n}\n\n#container p{\n    font-family: 'Inter', sans-serif;\n    font-weight: 700;\n    font-size: 40px;\n    text-align:center;\n}\n\n#link{\n    color:#990000;\n}\n\n#surveytitle-qrcode-container {\n    text-align: center;\n    margin-top: 50px;\n    margin-bottom: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
