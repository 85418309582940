import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Routes } from 'react-router-dom';

import Home from './pages/general/Home';
import StudentSignIn from './pages/student/StudentSignIn';
import Survey from './pages/student/Survey';
import CaseScenario from './pages/student/CaseScenario';
import SignIn from './pages/room/SignIn';
import Title from './pages/room/Title';
import SurveyTitle from './pages/room/SurveyTitle';
import RoomCaseScenario from './pages/room/RoomCaseScenario';
import RoomCaseScenario2 from './pages/room/RoomCaseScenario2';
import ThankYouTitle from './pages/room/ThankYouTitle';
import AdminSignInPage from './pages/admin/AdminSignInPage';
import AdminSessionsPage from './pages/admin/AdminSessionsPage';
import IndividualResults from './pages/admin/IndividualResults';
import GroupResults from './pages/admin/GroupResults';
import GroupResultsBreakdown from './pages/admin/GroupResultsBreakdown';
import AdminRoomSelectPage from './pages/admin/AdminRoomSelectPage';
import AdminSurveysSubmitted from './pages/admin/AdminSurveysSubmitted';
import AdminIndividualsResponded from './pages/admin/AdminIndividualsResponded';
import AdminGroupsResponded from './pages/admin/AdminGroupsResponded';
import AdminStartSurvey from './pages/admin/AdminStartSurvey';
import AdminDummyPage from './pages/admin/AdminDummyPage';
import AdminFlowNo5 from './pages/admin/AdminFlowNo5';
import AdminGenerateCase from './pages/admin/AdminGenerateCase';


function App() {
  return (
    <div className='wrapper'>
      <Routes>
        <Route path='/' element={<Home/>}/>
          
        {/* Admin Routes */}
        <Route path='/admin/dummypage' element={<AdminDummyPage/>} />
        <Route path='/admin/signin' element={<AdminSignInPage/>} />
        <Route path='/admin/sessions/:first/:last' element={<AdminSessionsPage/>} />
        <Route path='/admin/roomselect/:first/:last/:session' element={<AdminRoomSelectPage/>} />
        <Route path='/admin/survey/:first/:last/:session' element={<AdminStartSurvey/>} />
        <Route path='/admin/survey/url/:first/:last/:session' element={<AdminFlowNo5/>} />
        <Route path='/admin/generate-case/:first/:last/:session' element={<AdminGenerateCase/>} />
        <Route path='/admin/dashboard/surveys/:first/:last/:session' element={<AdminSurveysSubmitted/>} />
        <Route path='/admin/dashboard/indiv-response/:first/:last/:session' element={<AdminIndividualsResponded/>} />
        <Route path='/admin/dashboard/group-response/:first/:last/:session' element={<AdminGroupsResponded/>} />
        <Route path='/admin/results/individual/:first/:last/:session' element={<IndividualResults/>} />
        <Route path='/admin/results/group/:first/:last/:session' element={<GroupResults/>} />
        <Route path='/admin/results/groupbreakdown/:first/:last/:session/:room' element={<GroupResultsBreakdown/>} />

        {/* Room Routes */}
        <Route path='/room/signin' element={<SignIn/>} />
        <Route path='/room/title/:first/:last/:session/:building/:room' element={<Title/>} />
        <Route path='/room/surveytitle/:first/:last/:session/:building/:room' element={<SurveyTitle/>} />
        <Route path='/room/casescenario/:first/:last/:session/:room' element={<RoomCaseScenario/>} />
        <Route path='/room/casescenario2/:first/:last/:session/:room' element={<RoomCaseScenario2/>} />
        <Route path='/room/thankyoutitle/:first/:last/:session' element={<ThankYouTitle/>} />
        

        {/* Student Routes */}
        <Route path='/student/signin-case/:first/:last/:session' element={<StudentSignIn onlyCase={true} />} /> {/* student sign in page */}
        <Route path='/student/signin-all/:first/:last/:session' element={<StudentSignIn onlyCase={false} />} /> {/* student sign in page */}
        <Route path='/student/survey/:first/:last/:session' element={<Survey inElc={false} />} />
        <Route path='/student/survey-in-elc/:first/:last/:session/:building/:room' element={<Survey inElc={true} />} />
        <Route path='/student/case/:first/:last/:session/:building/:room' element={<CaseScenario />} />
        {/* <Route path='student/waitforcase/:first/:last/:session' element={<Wait message="Please wait for activity to start" />} /> */}
        
      </Routes>
    </div>
  );
}

export default App;
