import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  OverlayTrigger,
  Row,
  Stack,
  Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import USCLogoHeader from "../../components/USCLogoHeader";
import ClickableRedCard from "../../components/ClickableRedCard";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import YellowButton from "../../components/YellowButton";
import "./SignIn.css";
import GrayCard from "../../components/GrayCard";
import { getAvailableRoomsAPI, getSessionsAPI, startSessionAPI } from "../../util/API";

const minZoomRooms = 1;
const maxZoomRooms = 15;

function RoomSignIn({ onlyCase }) {
    const navigate = useNavigate();
    let [roomAvailability, setRoomAvailability] = useState([]);
    let [selectedBuilding, setSelectedBuilding] = useState();
    let [selectedRooms, setSelectedRooms] = useState([]);
    let [zoomRoomsCount, setZoomRoomCount] = useState(minZoomRooms);
    let [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getAvailableRoomsAPI((response) => {
            setRoomAvailability(response);
        });
    }, []);

    let toggleBuildingSelection = (building) => {
        setSelectedRooms([]);
        setZoomRoomCount(minZoomRooms);
        if (building === selectedBuilding) {
            setSelectedBuilding();
        } else {
            setSelectedBuilding(building);
        }
    };

    let toggleRoomSelection = (room) => {
        if (room === selectedRooms) {
          setSelectedRooms([]);
        } else {
          setSelectedRooms(room);
        }
    };

    let startSession = () => {
        getAvailableRoomsAPI(response => {
            let buildingInfo = response.find(element => element.building == selectedBuilding);
            let room = buildingInfo.rooms.find(element => element.name == selectedRooms);
            const { firstName, lastName, sessionId } = room.currentSession;
            navigate(`/room/title/${firstName}/${lastName}/${sessionId}/${selectedBuilding}/${selectedRooms}`);
        });
    };

    return (
        <div id="stu-signin-border">
            <USCLogoHeader />
            <p id="stu-signin-title">Room Sign In</p>
            <Row className="mx-5 pb-5 px-5 pt-5">
                <Col
                    lg={{ span: 3, offset: 2 }}
                    md={{ span: 4, offset: 2 }}
                    xs={{ span: 10, offset: 1 }}
                >
                    <div className="adminroomselect-labels mb-2">
                        Select Building
                    </div>
                    <Stack gap={3}>
                        {roomAvailability.map((obj, index) => {
                            return (
                                <ClickableRedCard
                                    key={index}
                                    text={obj.building}
                                    hasBorder={
                                        selectedBuilding === obj.building
                                    }
                                    onClick={() =>
                                        toggleBuildingSelection(obj.building)
                                    }
                                />
                            );
                        })}
                    </Stack>
                </Col>
                <Col
                    lg={{ span: 3, offset: 2 }}
                    md={{ span: 4, offset: 0 }}
                    xs={{ span: 10, offset: 1 }}
                >
                    <div className="adminroomselect-labels mb-2">
                      Select Room
                    </div>
                    <Rooms
                        roomAvailability={roomAvailability}
                        building={selectedBuilding}
                        selectedRooms={selectedRooms}
                        onRoomClick={toggleRoomSelection}
                    />
                </Col>
            </Row>
            <div
                className="adminroomselect-next-btn"
                hidden={selectedRooms.length === 0}
            >
                <YellowButton
                    text={"Next"}
                    width="120px"
                    onClick={startSession}el
                />
            </div>
        </div>
    );
}

let Rooms = ({
    roomAvailability,
    building,
    selectedRooms,
    onRoomClick,
    onBreakoutRoomChange,
}) => {
    for (let i = 0; i < roomAvailability.length; i++) {
        if (building === roomAvailability[i].building) {
            return (
                <Stack className="adminroomselect-scroll" gap={3}>
                    {roomAvailability[i].rooms.map((room, index) => {
                        
                            return (
                                <ClickableRedCard
                                    key={index}
                                    text={room.name}
                                    hasBorder={selectedRooms.includes(
                                        room.name
                                    )}
                                    onClick={() => onRoomClick(room.name)}
                                />
                            );
                        
                    })}
                </Stack>
            );
        }
    }

    return <></>;
    
};



export default RoomSignIn;