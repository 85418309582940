import React, { useEffect, useState } from "react";
import {
    Col,
    Form,
    Modal,
    ModalBody,
    ModalHeader,
    ModalTitle,
    OverlayTrigger,
    Row,
    Stack,
    Tooltip,
} from "react-bootstrap";
import ClassIDLabel from "../../components/ClassIDLabel";
import USCLogoHeader from "../../components/USCLogoHeader";
import AdminBackground from "./AdminBackground";
import ClickableRedCard from "../../components/ClickableRedCard";
import YellowButton from "../../components/YellowButton";
import "./AdminRoomSelectPage.css";
import GrayCard from "../../components/GrayCard";
import { useNavigate, useParams } from "react-router-dom";
import { getAvailableRoomsAPI, getSessionsAPI, startSessionAPI } from "../../util/API";

const minZoomRooms = 1;
const maxZoomRooms = 15;

function AdminRoomSelectPage() {
    const navigate = useNavigate();
    let [roomAvailability, setRoomAvailability] = useState([]);
    let [selectedBuilding, setSelectedBuilding] = useState();
    let [selectedRooms, setSelectedRooms] = useState([]);
    let [zoomRoomsCount, setZoomRoomCount] = useState(minZoomRooms);
    let [showModal, setShowModal] = useState(false);
    const { first, last, session } = useParams();

    useEffect(() => {
        getAvailableRoomsAPI((response) => {
            response = response.filter(item => item.building != "Zoom");
            setRoomAvailability(response);
        });
    }, []);

    let toggleBuildingSelection = (building) => {
        setSelectedRooms([]);
        setZoomRoomCount(minZoomRooms);
        if (building === selectedBuilding) {
            setSelectedBuilding();
        } else {
            setSelectedBuilding(building);
        }
    };

    let toggleRoomSelection = (room) => {
        const newArr = [...selectedRooms];
        const index = newArr.indexOf(room);
        index === -1 ? newArr.push(room) : newArr.splice(index, 1);
        const sortingArr = roomAvailability.find(
            (obj) => selectedBuilding === obj.building
        ).rooms;
        newArr.sort((a, b) => {
            return (
                sortingArr.findIndex((element) => a === element.name) -
                sortingArr.findIndex((element) => b === element.name)
            );
        });
        setSelectedRooms(newArr);
    };

    let startSession = () => {
        let goToNextPage = () => getSessionsAPI(first, last, (response) => {
            const sessionObj = response.find(element => element.sessionId === session);
            if (sessionObj.surveyAtElc) {
                navigate(`/admin/survey/${first}/${last}/${session}`);
            } else {
                navigate(`/admin/generate-case/${first}/${last}/${session}`)
            }
        });

        // start the session
        if (selectedBuilding != "Zoom") {
            startSessionAPI(first, last, session, selectedBuilding, selectedRooms, () => {
                goToNextPage();
            }, () => {
                alert("Some of the rooms are not available. Refresh the page and try again")
            });
        } else {
            startSessionAPI(first, last, session, selectedBuilding, zoomRoomsCount, () => {
                goToNextPage();
            })
        }
    };

    return (
        <>
            <AdminBackground />
            <USCLogoHeader />
            <ClassIDLabel class_id={session} />
            <Row className="mx-5 pb-5 px-5 pt-5">
                <Col
                    lg={{ span: 3, offset: 2 }}
                    md={{ span: 4, offset: 2 }}
                    xs={{ span: 10, offset: 1 }}
                >
                    <div className="adminroomselect-labels mb-2">
                        Select Building
                    </div>
                    <Stack gap={3}>
                        {roomAvailability.map((obj, index) => {
                            return (
                                <ClickableRedCard
                                    key={index}
                                    text={obj.building}
                                    hasBorder={
                                        selectedBuilding === obj.building
                                    }
                                    onClick={() =>
                                        toggleBuildingSelection(obj.building)
                                    }
                                />
                            );
                        })}
                        <ClickableRedCard
                            text={"Zoom"}
                            hasBorder={selectedBuilding === "Zoom"}
                            onClick={() => toggleBuildingSelection("Zoom")}
                        />
                    </Stack>
                </Col>
                <Col
                    lg={{ span: 3, offset: 2 }}
                    md={{ span: 4, offset: 0 }}
                    xs={{ span: 10, offset: 1 }}
                >
                    {selectedBuilding === "Zoom" ? (
                        <div className="adminroomselect-labels mb-2">
                            Select Number of Breakout Rooms
                        </div>
                    ) : (
                        <div className="adminroomselect-labels mb-2">
                            Select Rooms for the Session
                        </div>
                    )}
                    <Rooms
                        roomAvailability={roomAvailability}
                        building={selectedBuilding}
                        selectedRooms={selectedRooms}
                        onRoomClick={toggleRoomSelection}
                        onBreakoutRoomChange={(e) => {
                            setZoomRoomCount(e.target.value);
                        }}
                    />
                </Col>
            </Row>
            <div
                className="adminroomselect-next-btn"
                hidden={
                    selectedRooms.length === 0 &&
                    selectedBuilding !== "Zoom"
                }
            >
                <YellowButton
                    text={"Next"}
                    width="120px"
                    onClick={() => setShowModal(true)}
                />
            </div>

            <Modal
                centered
                show={showModal}
                className=""
                onHide={() => setShowModal(false)}
            >
                <ModalHeader closeButton>
                    <ModalTitle className="adminroomselect-modal-title">
                        Choose these rooms for the exercise?
                    </ModalTitle>
                </ModalHeader>
                <ModalBody className="mx-auto py-4 px-3">
                    {selectedBuilding === "Zoom" ? (
                        zoomRoomsCount > 1 ? (
                            <div>{zoomRoomsCount} Breakout Rooms</div>
                        ) : (
                            <div>{zoomRoomsCount} Breakout Room</div>
                        )
                    ) : (
                        selectedRooms.map((name, index) => {
                            return (
                                <div
                                    className="adminroomselect-modal-rooms"
                                    key={index}
                                >
                                    {name}
                                </div>
                            );
                        })
                    )}

                    <Stack direction="horizontal" gap={4} className="my-4">
                        <div className="ms-auto">
                            <YellowButton
                                text={"Yes"}
                                width="60px"
                                onClick={startSession}
                            />
                        </div>
                        <div className="me-auto">
                            <YellowButton
                                text={"No"}
                                width="60px"
                                onClick={() => setShowModal(false)}
                            />
                        </div>
                    </Stack>
                </ModalBody>
            </Modal>
        </>
    );
}

let Rooms = ({
    roomAvailability,
    building,
    selectedRooms,
    onRoomClick,
    onBreakoutRoomChange,
}) => {
    for (let i = 0; i < roomAvailability.length; i++) {
        if (building === roomAvailability[i].building) {
            return (
                <Stack className="adminroomselect-scroll" gap={3}>
                    {roomAvailability[i].rooms.map((room, index) => {
                        if (room.currentSession == null) {
                            return (
                                <ClickableRedCard
                                    key={index}
                                    text={room.name}
                                    hasBorder={selectedRooms.includes(
                                        room.name
                                    )}
                                    onClick={() => onRoomClick(room.name)}
                                />
                            );
                        } else {
                            return (
                                <OverlayTrigger
                                    key={index}
                                    placement={"top"}
                                    overlay={
                                        <Tooltip>
                                            Currently used by{" "}
                                            {room.currentSession.firstName}{" "}
                                            {room.currentSession.lastName},
                                            Session{" "}
                                            {room.currentSession.sessionId}
                                        </Tooltip>
                                    }
                                >
                                    <div>
                                        <GrayCard text={room.name} />
                                    </div>
                                </OverlayTrigger>
                            );
                        }
                    })}
                </Stack>
            );
        }
    }

    const options = [];
    for (let i = minZoomRooms; i <= maxZoomRooms; i++) {
        options.push(<option key={i}>{i}</option>);
    }
    if (building === "Zoom") {
        return (
            <Form.Select
                className="adminroomselect-roomnumber fs-6"
                onInput={onBreakoutRoomChange}
            >
                {options}
            </Form.Select>
        );
    }

    return <></>;
};

export default AdminRoomSelectPage;
