// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#adminflowno5-title {\n  text-align: center;\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 20px;\n  margin-top: 100px;\n  margin-bottom: 5px;\n}\n\n#adminflowno5-next-btn {\n  float: right;\n  margin-right: 180px;\n  margin-bottom: 50px;\n  margin-top: 30px;\n  text-align: center;\n}\n\n#survey-qrcode-container {\n  text-align: center;\n  margin-top: 80px;\n}", "",{"version":3,"sources":["webpack://./src/pages/admin/AdminFlowNo5.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter&display=swap\");\n\n#adminflowno5-title {\n  text-align: center;\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 20px;\n  margin-top: 100px;\n  margin-bottom: 5px;\n}\n\n#adminflowno5-next-btn {\n  float: right;\n  margin-right: 180px;\n  margin-bottom: 50px;\n  margin-top: 30px;\n  text-align: center;\n}\n\n#survey-qrcode-container {\n  text-align: center;\n  margin-top: 80px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
