import React, { useEffect, useState} from 'react';
import { Card } from "react-bootstrap";
import "./ClickableRedCard.css";
import Button from "react-bootstrap/esm/Button";

// const { MongoClient } = require("mongodb");

function AdminDummyComponent({ text, width, hasBorder, onClick }) {
    /* Copied from server.js (need to replace eventually) */
    // const { MongoClient } = require("mongodb");
    // const uri = "mongodb+srv://ELC:ELC123@cluster0.0duwjbf.mongodb.net/?retryWrites=true&w=majority";
    // const client = new MongoClient(uri);
    
    // async function connect() {
    //     try {
    //         await client.connect();
    //         // database and collection code goes here
    //         const db = client.db("MyFriendMorganDB");
    //         const coll = db.collection("surveyResponses");

    //         // insert code goes here
    //         const docs = [
    //             {question1: 0, question2: 1},
    //             {question1: 1, question2: 1},
    //             {question1: 2, question2: 2}
    //             // {name: "Wild2", officialName: "81P/Wild", orbitalPeriod: 6.41, radius: 1.5534, mass: 2.3e13},
    //             // {name: "Comet Hyakutake", officialName: "C/1996 B2", orbitalPeriod: 17000, radius: 0.77671, mass: 8.8e12}

    //             //tip: If you omit the _id field, the driver automatically generates a unique ObjectId value for the _id field.
    //           ];
            
    //         const result = await coll.insertMany(docs);

    //         // display the results of your operation
    //         //Many write operations in MongoDB return a result object that contains information about the operation. For insert operations, the result object will contain the _id of documents the driver successfully inserted. Here, you access this property and print them.
    //         console.log(result.insertedIds);

    //     } catch (err) {
    //         console.log(err.stack);
    //     }
    //     finally {
    //         // Ensures that the client will close when you finish/error
    //         await client.close();
    //     }
    // }
    
    // connect(); //connect().catch(console.dir);

    /* ok actual code now */
    var totalSurveysSubmitted = 7;
    
    const [backendData, setbackendData] = useState([{}]);

    useEffect(() => {
      fetch('/api').then(
        response => response.json()
      ).then(
        data => {
          setbackendData(data)
        }
      );
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
    }

    return (
        <div>
            <Card>
                <Card.Body className="py-1">
                    <Card.Title>Text from Admin Dummy Component</Card.Title>
                    <Card.Text className="fs-5 fw-bold">
                        Total number of surveys submitted: {totalSurveysSubmitted}
                    </Card.Text>
                    <Button variant="primary">Go somewhere</Button>
                </Card.Body>
            </Card>


            <form onSubmit={handleSubmit}>
                <div className="form-input">
                    <input 
                    type="text"
                    name="title"
                    placeholder="Title"
                    />
                </div>
                <div className="form-input">
                    <textarea
                    placeholder="body"
                    name="body"
                    cols="30"
                    rows="10"
                    >
                    
                    </textarea>
                </div>

                <button type="submit">Submit</button>
            </form>

        
            {(typeof backendData.users === 'undefined') ? (
                <p>Loading...</p>
            ): (
                backendData.users.map((user, i) => (
                    <p key={i}>{user}</p>
                ))
            )}
        
        </div>
    );
}

export default AdminDummyComponent;
