import React from "react";
import "./AdminGenerateCase.css";
import USCLogoHeader from "../../components/USCLogoHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import AdminBackground from "./AdminBackground";
import YellowButton from "../../components/YellowButton";
import ClassIDLabel from "../../components/ClassIDLabel";
import { useNavigate } from "react-router-dom";
import { startCaseScenarioAPI } from "../../util/API";

function AdminGenerateCase() {
    const {first, last, session} = useParams();
    const navigate = useNavigate();


    let generateCaseScenario = async () => {
        let response = await startCaseScenarioAPI(first, last, session);
        
        if (response == "ECONNABORTED") {
            alert("Please refresh the page and click the button again");
        } else {
            navigate(`/admin/dashboard/indiv-response/${first}/${last}/${session}`);
        }
    }

    return (
        <div>
            <ClassIDLabel class_id={session} />
            <AdminBackground />
            <USCLogoHeader />
            <div className="admingeneratecase-btn">
                <YellowButton text="Generate Case Scenario" onClick={generateCaseScenario}/>
            </div>
        </div>
    );
}

export default AdminGenerateCase;
