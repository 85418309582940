import "./GroupResults.css";
import React, { useState, useEffect } from "react";
import USCLogoHeader from "../../components/USCLogoHeader";
import { useNavigate, useParams } from "react-router-dom";
import { deleteSessionAPI, downloadResults, getGroupResults } from "../../util/API";
import YellowButton from "../../components/YellowButton";
import { Container, Row, Col } from "react-bootstrap"

function GroupResults() {
  const {first, last, session} = useParams()
  const navigate = useNavigate();

  let [heightYes, setHeightYes] = useState([]);
  let [heightNo, setHeightNo] = useState([]);
  let [total, setTotal] = useState([]);
  let [yesRooms, setYesRooms] = useState(null);
  let [noRooms, setNoRooms] = useState([]);
  let [yesRoomButtons, setYesRoomButtons] = useState([]);
  let [noRoomButtons, setNoRoomButtons] = useState([]);

  useEffect(() => {
    getGroupResults(first, last, session, (response) => {
      setTotal(response[0].length + response[1].length);
      setHeightYes(response[0].length);
      setHeightNo(response[1].length);
      setYesRooms(response[0]);
      setNoRooms(response[1]);
    });
  }, []);

  useEffect(() => {
    if (yesRooms) {
      let yesButtons = yesRooms.map((room, index) => {
        return (<button key={room._id} onClick={() => navigate(`/admin/results/groupbreakdown/${first}/${last}/${session}/${room.room}`)} style={{ border: "none", backgroundColor: "transparent", borderRadius: "10px", fontWeight: "bold"}} onMouseOver={(e) => e.target.style.backgroundColor = "#edbe00"} onMouseOut={(e) => e.target.style.backgroundColor = "transparent"}>
          {room.room}
        </button>)
      });
      setYesRoomButtons(yesButtons);
    }
    if (noRooms) {
      let noButtons = noRooms.map((room, index) => {
        return (<button key={room._id} onClick={() => navigate(`/admin/results/groupbreakdown/${first}/${last}/${session}/${room.room}`)} style={{ border: "none", backgroundColor: "transparent", borderRadius: "10px", fontWeight: "bold"}}onMouseOver={(e) => e.target.style.backgroundColor = "#edbe00"} onMouseOut={(e) => e.target.style.backgroundColor = "transparent"}>
          {room.room}
        </button>)
      });
      setNoRoomButtons(noButtons);
    }
  }, [yesRooms, noRooms]);


  let endSession = async () => {
    let endSession = window.confirm("Are you sure you want to end the session?");
    if (endSession) {
      let response = await downloadResults(first, last, session);
      if (response.status == 500) {
        alert("Something went wrong, the session might not exist");
      } else {
        await deleteSessionAPI(first, last, session);
        navigate("/admin/signin");
      }
    }
  }

  return (
    <Container fluid>
      <USCLogoHeader />
      <Row>
        <p id="group-results-title">Do you recommend Morgan?</p>
      </Row>
      <Row>
        <p id="group-results-subtitle">Class Results</p>
      </Row>

      <div>
        <Row>
          <Col>
            <div className="group-results-rectangleYes" style={{ height: heightYes/total*300}}/>
          </Col>
          
          <Col>
            <div className="group-results-rectangleNo" style={{ height: heightNo/total*300 }}/>
          </Col>
        </Row>
      </div>

      <Row>
        <Col>
          <p id="group-results-answer-titleYes">Yes</p>
        </Col>
        <Col>
          <p id="group-results-answer-titleNo">No</p>
        </Col>
      </Row>

      <Row>
        <Col>
        <div style={{display: "flex", flexDirection: "column", marginLeft: "55%"}}>
          {yesRoomButtons}
          </div>
        </Col>
        <Col>
        <div style={{display: "flex", flexDirection: "column", marginRight: "55%"}}>
          {noRoomButtons}
          </div>
        </Col>
      </Row>


      <Row >
        <Col id="group-results-next-btn">
          <YellowButton text={"End Session"} width={150} onClick={endSession} />
        </Col>
      
      </Row>
    </Container>

  );
}

export default GroupResults;
