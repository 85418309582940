import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import USCLogoHeader from "../../components/USCLogoHeader";
import Case from '../../components/Case.js';
import YellowButton from '../../components/YellowButton';
import { useNavigate, useParams } from 'react-router-dom';
import '../student/StudentPages.css';
import Wait from '../general/Wait';
import { getCaseScenarioInfo, submitGroupResponse } from '../../util/API';

function RoomCaseScenario2(){
    let {first, last, session, building, room} = useParams()

    const [selectedOption1, setSelectedOption1] = useState(null);
    const [reason, setReason] = useState('');
    const [selectedOption3, setSelectedOption3] = useState(null);
    const [selectedOption4, setSelectedOption4] = useState(null);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    let [caseScenario, setCaseScenario] = useState('');
    let [invalidPage, setInvalidPage] = useState(false);

    useEffect(() => {
      console.log("Getting case scenario");
      getCaseScenarioInfo(first, last, session, room).then(response => {
        if (response.status == 500) {
          alert(response.data);
          setInvalidPage(true);
        } else if (response == "ECONNABORTED") {
          window.location.reload();
        } else {
          setCaseScenario(response.data.caseScenario);
        }
      });
    }, []);

    const handleOption1Change = (event) => {
        setSelectedOption1(event.target.value);
    };
    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };
    const handleOption3Change = (event) => {
        setSelectedOption3(event.target.value);
      };
    
    const handleOption4Change = (event) => {
        setSelectedOption4(event.target.value);
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (invalidPage) {
          alert("Something went wrong, can not submit response");
          return;
        }
      try{
        const groupFormData = {
          'first': first,
          'last': last, 
          'sessionID': session, 
          'building': building, 
          'room':room,
          'recommendation': selectedOption1,
          'reason': reason,
          'gender': selectedOption3, 
          'genderReason': selectedOption4,
        };

        const response = await submitGroupResponse(groupFormData);
        console.log(groupFormData);
        console.log(response.data);
        setHasSubmitted(true);
      } catch (error) {
        console.error(error);
      }
    }

    if (hasSubmitted) {
      return (
        <Wait message="Thank you - your response has been submitted!" />
      )
    }

    return(
        <Container className="student-border-style">
            <USCLogoHeader/>
            <h2 className='student-header'>Case Scenario</h2>
            <Case caseScenario={caseScenario}/>
            <Form >
                <Form.Group className='student-case-scenario-padding2'>
                    <Form.Label><strong>As a group, would you recommend Morgan?</strong></Form.Label>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        name="option1"
                        value="Yes"
                        checked={selectedOption1 === 'Yes'}
                        onChange={handleOption1Change}
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        name="option2"
                        value="No"
                        checked={selectedOption1 === 'No'}
                        onChange={handleOption1Change}
                    />
                </Form.Group>

                <Form.Group className='student-case-scenario-padding'>
                    <Form.Label htmlFor="reason"><strong>Why or why not?</strong></Form.Label>
                    <Form.Control
                        type="text"
                        id="reason"
                        value={reason}
                        onChange={handleReasonChange}
                    />
                </Form.Group>

        <Form.Group className='student-case-scenario-padding2'>
        <Form.Label><strong>When you were reading the scenario, what gender did you imagine for Morgan?</strong></Form.Label>
        <Form.Check
          type="radio"
          label="Female"
          name="option3"
          value="Female"
          checked={selectedOption3 === 'Female'}
          onChange={handleOption3Change}
        />
        <Form.Check
          type="radio"
          label="Male"
          name="option4"
          value="Male"
          checked={selectedOption3 === 'Male'}
          onChange={handleOption3Change}
        />
        <Form.Check
          type="radio"
          label="Nonbinary"
          name="option5"
          value="Nonbinary"
          checked={selectedOption3 === 'Nonbinary'}
          onChange={handleOption3Change}
        />
      </Form.Group>

      <Form.Group className='student-case-scenario-padding'>
        <Form.Label><strong>What factor most heavily impacted your choice?</strong></Form.Label>
        <Form.Check
          type="radio"
          label="Loyalty to a friend"
          name="option6"
          value="Loyalty to a friend"
          checked={selectedOption4 === 'Loyalty to a friend'}
          onChange={handleOption4Change}
        />
        <Form.Check
          type="radio"
          label="Best interest of the company"
          name="option7"
          value="Best interest of the company"
          checked={selectedOption4 === 'Best interest of the company'}
          onChange={handleOption4Change}
        />
      </Form.Group>

        <div className='student-case-scenario-flexend'>
        <YellowButton onClick={handleSubmit} text="Submit"/>
        </div>
            </Form>
        </Container>
    );
}

export default RoomCaseScenario2;

