import "./AdminFlowNo5.css";
import USCLogoHeader from "../../components/USCLogoHeader";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import AdminBackground from "./AdminBackground";
import YellowButton from "../../components/YellowButton";
import RedCard from "../../components/RedCard";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getOutsideURLAPI } from "../../util/API";
import ClassIDLabel from "../../components/ClassIDLabel";
import ClickableRedCard from "../../components/ClickableRedCard";

function AdminFlow() {

  const {first, last, session} = useParams();
  const navigate = useNavigate();

  let [url, setURL] = useState([]);

  getOutsideURLAPI(first, last, session, (response) => {
    setURL(response.url);
  });

  let returnToHomePage = () => {
    navigate('/admin/signin');
  }

  return (
    
    <Container fluid>
      <AdminBackground />
      <USCLogoHeader />
      <ClassIDLabel class_id={session} id="class-session"/>
      <Row  id="survey-qrcode-container">
        <Col>
        <Image width="200px" src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${url}`} alt="QR Code failed to load" />
        </Col>
      </Row>

      <Row >
        <Col >
            <p id="adminflowno5-title">{url} </p>
        </Col>
      </Row>

      <Row id="adminflowno5-next-btn">
        <Col>
        <ClickableRedCard text={"Return to Home Page"} onClick={returnToHomePage} />
        </Col>
      </Row>
    

    </Container>
    
    
    // <>
    //   <AdminBackground />
    //   <USCLogoHeader />
    //   <ClassIDLabel class_id={session} />
    //   <div id='survey-qrcode-container'>
    //       <Image width="200px" src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${url}`} />
    //   </div>
    //   <p id="adminflowno5-title">{url}</p>

    //   <div id="adminflowno5-next-btn">
    //     <ClickableRedCard text={"Return to Home Page"} onClick={returnToHomePage} />
    //   </div>
    // </>
  );
}

export default AdminFlow;
