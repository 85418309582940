import './RoomCaseScenario.css'
import USCLogoHeader from "../../components/USCLogoHeader";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Case from '../../components/Case';
import { Image } from 'react-bootstrap';
import { exerciseEventsUrl, getCaseScenarioInfo, getSessionsAPI } from '../../util/API';

function RoomCaseScenario(){
    const { first, last, session, room } = useParams();
    const navigate = useNavigate();
    let [caseScenario, setCaseScenario] = useState('');
    let [studentUrl, setStudentUrl] = useState('');
    let [surveyAtElc, setSurveyAtElc] = useState(false);

    useEffect(() => {
        getCaseScenarioInfo(first, last, session, room).then(response => {
            if (response.status == 500) {
                alert(response.data);
            } else {
                setCaseScenario(response.data.caseScenario);
                setStudentUrl(response.data.studentUrl)
            }
        });

        getSessionsAPI(first, last, (response) => {
            const sessionObj = response.find(element => element.sessionId === session);
            setSurveyAtElc(sessionObj.surveyAtElc);
            console.log(sessionObj);
        })
    }, []);
    
    //set up event listener 
    const adminEventSource = new EventSource(exerciseEventsUrl);

    adminEventSource.onerror = () => {
        console.log("event source error");
        console.log(adminEventSource);
    }

    
    adminEventSource.addEventListener('start-group-response', event => {
        var data = JSON.parse(event.data);
        //go to room case scenario 2 if the event is for this session
        if (data.firstName == first && data.lastName == last && data.sessionId == session) {
            console.log("add event listener working")
            adminEventSource.close();
            navigate(`/room/casescenario2/${first}/${last}/${session}/${room}`);
        }
    })

    return(
        <>
            <USCLogoHeader/>
            <p id="scenario-title">Case Scenario</p>
            <div id="description-box">
                <Case caseScenario={caseScenario} />
                { !surveyAtElc &&
                    <div id='roomcasescenario-qrcode-container'>
                        <Image width="200px" src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${studentUrl}`} alt='QR Code failed to load' />
                    </div>
                }
                { !surveyAtElc && 
                    <p id="scenario-link">{studentUrl}</p>
                }
            </div>
        </>

    );
}

export default RoomCaseScenario;