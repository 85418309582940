import { useState } from "react";
import "./AdminIndividualsResponded.css";
import USCLogoHeader from "../../components/USCLogoHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import AdminBackground from "./AdminBackground";
import YellowButton from "../../components/YellowButton";
import ActivityDashboard from "../../components/ActivityDashboard";
import ClassIDLabel from "../../components/ClassIDLabel";
import { startGroupResponseAPI, submitEventsUrl } from "../../util/API";
import { useNavigate } from 'react-router-dom';



function AdminFlow() {
  let { first, last, session } = useParams();
  const navigate = useNavigate();

  /* Added event source */
  let [roomCounts, setRoomCounts] = useState([]);
  const indivResponseEventSource = new EventSource(submitEventsUrl);

  indivResponseEventSource.onerror = () => {
    console.log("error");
  }

  indivResponseEventSource.addEventListener('individual-response', event => {
    var json = JSON.parse(event.data);
    
    if (first == json.firstName && session == json.session) {
      setRoomCounts(json.arrayResponses);
    }
  })
  
  /* *********************** */

  let handleGroupResponseButton = async () => {
    console.log("group response clicked");
    console.log("params: "+first+ last + session);
    
    let response = await startGroupResponseAPI(first, last, session);

    if (response == "ECONNABORTED") {
      alert("Please refresh the page and click the button again");
    } else {
      //navigate to next page
      navigate(`/admin/dashboard/group-response/${first}/${last}/${session}`)
    }
  };

  return (
    <div className="AdminFlow">
      <AdminBackground />
      <ClassIDLabel class_id={session} isWhite />
      <Container fluid className="ps-0">
        <Row>
          <Col sm={12} md={7} lg={5}>
            <ActivityDashboard title={"Individuals Responded"} roomCounts={roomCounts}/>
          </Col>
          <Col>
            <Row className="mb-5 pb-5" id="adminindivresponded-header">
                <USCLogoHeader />
            </Row>
            <Row id="adminindivresponded-button" className="pt-5">
                <YellowButton text="Start Group Response" onClick={() => handleGroupResponseButton()}/>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AdminFlow;
