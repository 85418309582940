import "./IndividualResults.css";
import React, { useEffect, useState } from "react";
import USCLogoHeader from "../../components/USCLogoHeader";
import { useNavigate, useParams } from "react-router-dom";
import { getIndividualResults } from "../../util/API";
import YellowButton from "../../components/YellowButton";
import { Container, Row, Col } from "react-bootstrap"

function IndividualResults() {
  const {first, last, session} = useParams()
  const navigate = useNavigate();

  let [heightYes, setHeightYes] = useState([]);
  let [heightNo, setHeightNo] = useState([]);
  let [total, setTotal] = useState([]);

  useEffect(() => {
    getIndividualResults(first, last, session, (response) => {
      if (response == "ECONNABORTED") {
        alert("Something went wrong, please refresh the page");
      } else {
        setTotal(response[0] + response[1]);
        setHeightYes(response[0]);
        setHeightNo(response[1]);
      }
    });
  }, []);

  
  let goToGroupResultsPage = () => navigate(`/admin/results/group/${first}/${last}/${session}`);

  return (

    <Container fluid>
      <USCLogoHeader />
      <Row>
        <p id="indiv-results-title">Do you recommend Morgan?</p>
      </Row>
      <Row>
        <p id="indiv-results-subtitle">Individual Results</p>
      </Row>
      <div>
        <Row>

          <Col className="individual-col-div">
            <div className="indiv-results-rectangleYes" style={{ height: heightYes/total*300}} />
          </Col>
          
          <Col className="individual-col-div">
            <div className="indiv-results-rectangleNo" style={{ height: heightNo/total*300 }}/>
          </Col>
        </Row>
      </div>

      <Row>
        <Col>
          <p id="indiv-results-answer-titleYes">Yes</p>
        </Col>
        <Col>
          <p id="indiv-results-answer-titleNo">No</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <p id="indiv-results-number-titleYes">{heightYes}</p>
        </Col>
        <Col>
          <p id="indiv-results-number-titleNo">{heightNo}</p>

        </Col>
      </Row>

      <Row >
        <Col id="indiv-results-next-btn">
          <YellowButton text={"Next"} width={100} onClick={goToGroupResultsPage} />  
        </Col>
      
      </Row>
    </Container>

  );
}

export default IndividualResults;
