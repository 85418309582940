import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./RedCard.css"

function RedCard({text, text2, width}) {
    return (
        <Card className="red-card" style={{width: width}}>
            <Card.Body className="py-1">
                <Row>
                    <Col>
                        <Card.Text className="fs-5 fw-bold">{text}</Card.Text>
                    </Col>
                    { text2 != null 
                        ? <Col>
                            <Card.Text className="fs-5 fw-bold red-card-right-column">{text2}</Card.Text>
                        </Col>
                        : <></>
                    }
                </Row>
            </Card.Body>
        </Card>
    )
}

export default RedCard;