import './ThankYouTitle.css'
import USCLogoHeader from "../../components/USCLogoHeader";

function ThankYouTitle(){
    return(
        <>
            <USCLogoHeader/>
            <div id="titleContainer">
                <p id="thank-you-title"> Thank you - your response has been submitted!</p>
            </div>
            
        </>
    );
}

export default ThankYouTitle;