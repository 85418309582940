import '../../App.css';
import React from 'react';
import USCLogoHeader from '../../components/USCLogoHeader.js';


function Wait({message}){

    const styles = {
        container: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        },
        centeredText: {
          textAlign: 'center',
        },
      };

    return(
        <div>
            <USCLogoHeader/>
            <div style={styles.container}>
                <h1 style={styles.centeredText}>{message}</h1>
            </div>
        </div>
        
    );
}
export default Wait;