import React, { useEffect, useState } from "react";
import "./AdminStartSurvey.css";
import USCLogoHeader from "../../components/USCLogoHeader";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import AdminBackground from "./AdminBackground";
import YellowButton from "../../components/YellowButton";
import RedCard from "../../components/RedCard";
import ClassIDLabel from "../../components/ClassIDLabel";
import { useNavigate } from "react-router-dom";
import { getSessionsAPI, startSurveyAPI } from "../../util/API";

function AdminFlow() {

  let [surveyAtElc, setSurveyAtElc] = useState();

  const {first, last, session} = useParams();
  const navigate = useNavigate();

  let getSurveyAtElcBool = () => {

    getSessionsAPI(first, last, (response) => {
      let sessionList = response;

      //loop through to find the right session 
      for(let i = 0; i < sessionList.length; i++){

        if (sessionList[i].sessionId == session){

          console.log("survey at ELC:" + sessionList[i].surveyAtElc )
          setSurveyAtElc(sessionList[i].surveyAtElc);

        }
      }

    })

    
  }

  useEffect(() =>{
    getSurveyAtElcBool();
  }, []);


  let handleSubmit = () => {

    startSurveyAPI(first, last, session);
    
    if(surveyAtElc){
      navigate(`/admin/dashboard/surveys/${first}/${last}/${session}`)
    }
    else{ //not at ELC
      navigate(`/admin/survey/url/${first}/${last}/${session}`)
    }
    
    
  };



  return (
    <div>
      <ClassIDLabel class_id={session} />

      <AdminBackground />
      <USCLogoHeader />

      <div className="adminstartsurvey-next-btn">
        <YellowButton text="Start Survey" onClick={handleSubmit}/>
      </div>
    </div>
  );
}

export default AdminFlow;
