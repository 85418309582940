// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home-container {\n  text-align: center;\n  margin-top: 6%;\n  font-family: \"Inter\", sans-serif;\n  font-size: rem;\n}\n\n.home-adminbutton{\n  margin-top: 5%;\n  margin-bottom: 1%;\n}", "",{"version":3,"sources":["webpack://./src/pages/general/Home.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter&display=swap\");\n\n.home-container {\n  text-align: center;\n  margin-top: 6%;\n  font-family: \"Inter\", sans-serif;\n  font-size: rem;\n}\n\n.home-adminbutton{\n  margin-top: 5%;\n  margin-bottom: 1%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
