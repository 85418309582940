import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ActivityDashboard.css"
import RedCard from "./RedCard";

// roomCounts is an array of objects: [ {name: String, count: int"}, {name: String, count: int"}, ... ]
function ActivityDashboard({title, roomCounts}) {
    let totalStudents = 0;
    for (let i = 0; i < roomCounts.length; i++) {
        totalStudents += roomCounts[i].count;
    }

    return (
        <Container id="activitydashboard-container">
            <div id="activitydashboard-background"></div>
            <div className="pt-5">
                <Row className="mt-5">
                    <Col>
                        <Row>
                            <p id="activitydashboard-text">ACTIVITY DASHBOARD</p>
                        </Row>
                        <Row>
                            <p id="activitydashboard-text1">{title}</p>
                        </Row>
                        <div id="activitydashboard-scroll">
                            {roomCounts.map((room, index) => {
                                return (
                                    <Row key={index} className="mb-4">
                                        <RedCard text={room.name} text2={room.count} />
                                    </Row>
                                );
                            })}
                        </div>
                        <br />
                        <Row>
                            <Col id="activitydashboard-text1" md="8">
                                Total Students
                            </Col>
                            <Col id="activitydashboard-text2" md="1">
                                { totalStudents }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default ActivityDashboard;