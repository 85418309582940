import React from "react";
import { Image } from "react-bootstrap";
import './USCLogoHeader.css';

function USCLogoHeader() {
    return (
        <div className="usc-logo-header-container">
            <Image className="my-4" src={require("../images/usc_logo.png")} width="100px" />
        </div>
    )
}

export default USCLogoHeader;