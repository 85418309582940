import React from "react";

function Case ({caseScenario}){
    const styles = {
        container: {
          width: '100%',
          height: '300px',
          overflow: 'auto',
          background: '#e9eef7',
          borderRadius: '15px',
          padding: '10px',
        },
        content: {
          width: '100%',
          padding: '10px',
        },
        pre: {
          whiteSpace: "pre-wrap",
        }
      };

    return(
        <div style={styles.container}>
            <div style={styles.content}>
              <pre style={styles.pre}>{caseScenario}</pre>
            </div>
        </div>
    );
}

export default Case;