// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#thank-you-title{\n    text-align:center;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 70px;\n    margin-top:130px;\n}\n\n#titleContainer{\n    width:80%;\n    margin:auto;\n}", "",{"version":3,"sources":["webpack://./src/pages/room/ThankYouTitle.css"],"names":[],"mappings":"AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,WAAW;AACf","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');\n\n#thank-you-title{\n    text-align:center;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 70px;\n    margin-top:130px;\n}\n\n#titleContainer{\n    width:80%;\n    margin:auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
